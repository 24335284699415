<template>
	<div class="bg">
		<div class="head">
    		<div class="container">
				<div class="container_box">
					<div class="nav_box">
						<div class="right">
							<ul class="nav">
								<li><a href="https://www.zhilanbofeng.cn/index.html">首页</a></li>
								<li><a href="https://www.zhilanbofeng.cn/product.html">服务器产品</a></li>
								<li class="dropul">
									<a href="###">解决方案</a>
									<div class="drop">
										<ul>
											<li><a href="https://www.zhilanbofeng.cn/ecology.html" style="line-height: 40px;">全产业生态</a></li>
											<li><a href="https://www.zhilanbofeng.cn/storage.html" style="line-height: 40px;">下一代云存储</a></li>
										</ul>
									</div>
								</li>
								<li><a href="https://www.zhilanbofeng.cn/about.html">关于我们</a></li>
							</ul>
							<div class="operation">
								<div>
									<img src="../../assets/img/ic_denglu.png" alt="智岚博丰">
								</div>
								<div class="login"><a href="/login">登录</a></div>
								<div class="register"><a @click="register">注册</a></div>
							</div>
						</div>
					</div>
				</div>
    		</div>
		</div>
    <div class="operation-box">
        <div class="outside-box">
            <div class="options">
                <a class="login" href="/login">密码登录</a>
                <a class="register" @click="register">用户注册</a>
            </div>
            <div class="form-box">
              <el-form :model="form" :rules="rules" ref="form"  class="demo-ruleForm">
                <el-form-item  prop="Passport" style="text-align:center;">
                  <el-input placeholder="请输入账号/手机号" prefix-icon="el-icon-lock" v-model="form.Passport" key="Passport"></el-input>
                </el-form-item>
                <el-form-item  prop="Password" style="text-align:center;">
                  <el-input placeholder="请输入密码" prefix-icon="el-icon-edit" v-model="form.Password" type="password" key="Password"></el-input>
                </el-form-item>
				<div class="code">
					<el-form-item  prop="verifyCode" style="text-align:center;display: inline-block;margin-bottom: 0;">
					  <el-input placeholder="请输入验证码" prefix-icon="el-icon-edit" v-model="form.verifyCode"  key="verifyCode" @keyup.enter.native="handleLogin"></el-input>
					</el-form-item>
					<img style="width: 120px; height: 40px;display: inline-block;margin-left: 10px;" :src="form.verifyCodeImg" @click="handleRefresh()">
				</div>
				<el-form-item  prop="verifyKey" style="display: none;">
				  <el-input placeholder="请输入密码" prefix-icon="el-icon-edit" v-model="form.verifyKey" key="verifyKey"></el-input>
				</el-form-item>
                <el-form-item style="text-align:center;">
                  <div style="text-align:left;font-size:18px;color:#333333;cursor: pointer;"><a @click="register">还没有账号？立即注册</a></div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click.native="handleLogin" >登录</el-button>
                </el-form-item>
              </el-form>
            </div>
        </div>
    </div>
	</div>
</template>

<script>
import {Admin} from "@/api/index"
export default {
    data() {
      return {
        form: {
          Passport: '',
          Password: '',
		  verifyKey:'',
		  verifyCodeImg:'',
        },
        rules: {
          Passport: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          Password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
        }
      };
    },
	created() {
		this.handleCaptcha()
	},
    methods: {
		//获取验证码
		async handleCaptcha(values) {
			let { status, data } = await Admin.verifyCode();
			if (status) {
				if (data.code === 0) {
					this.form.verifyCodeImg = data.data.img
					this.form.verifyKey = data.data.key
				} 
			} else {
				this.$message.error(data.message)
			}
		},
		//点击图片重新获取验证码
		handleRefresh() {
			this.handleCaptcha();
		},
      handleLogin() {
		  // this.$message.error("后台正在维护，开放时间另行通知。");
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						let { status, data } = await Admin.login(this.form);
						if (status) {
							if(data.code != 0){
								this.$message.error(data.message);
								this.handleCaptcha()
								return
							}
							this.$message.success("登陆成功");
							this.$store.commit('setToken', data.data.token);
							this.$router.replace('/home/index')
						} else {
							this.$message.error("data.message")
						}
					}
				});
			},
			register() {
				this.$router.replace('/register')
			}
			
    },
 
  }
</script>

<style lang="less" scoped="scoped">
html {
	width: 100%;
	height: 100%;
}
body{
	width: 100%;
	height: 100%;
}
.bg {
	width: 100%;
	height: 100%;
	background: url(../../assets/img/ic_denglubeijing.jpg) no-repeat center center;
	background-size: cover;
}
ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
.head {
  height: 89px;
  display: none;
}
.head .container {
  width: 90%;
  margin: 0 auto;
}
.head .container_box {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.nav_box {
  width: 100%;
  display: flex;
  position: relative;
}
.head .container .logo {
  padding:36px 0 19px 0;
  position: absolute;
  left: 0;
}
.head .container .right {
  display: flex;
  position: absolute;
  right: 0;
}
.head .container .nav {
  list-style-type: none;
  display: flex;
  padding-left: 0;
  height: 50px;
}
.head .container .nav li {
  margin: 0 50px 0 0;
}
.head .container .nav li:first-child {
  margin: 0 50px 0 0;
}
.head .container .nav li a {
  font-size: 16px;
  color: #fff;
  line-height: 89px;
}
.head .container .operation {
  display: flex;
  align-items: center;
  right: 0;
  color: #fff;
  padding: 30px 22px 29px 0;
  position: relative;
}
.head .container .operation > div {
  height: 30px;
  vertical-align: middle;
}
.head .container .operation a {
  font-size: 16px;
  color: #fff;
  line-height: 30px;
}
.head .container .operation a:hover {
  color: #0088ff;
}
.head .container .operation > div {
  display: flex;
  align-items: center;
}
.head .container .operation > div > img {
  height: 24px;
  width: 21px;
  margin-right: 9px;
}
.head .container .operation .login {
  margin-right: 15px;
}
.head .container .operation .register {
  margin-left: 15px;
  cursor: pointer;
}
.head .container .operation .auth {
  margin-right: 15px;
  display: none;
}
.head .container .operation .enter {
  margin-left: 15px;
  display: none;
}
.head .container .operation .login::before {
  content: "";
  width: 2px;
  height: 16px;
  background-color: #ccc;
  position: absolute;
  top: 39px;
  right: 68px;
}
.drop ul {
  background-color: #2695f6;
  width: 120px;
  text-align: center;
  position: absolute;
  top: 64px;
  right: 283px;
  border-radius: 6px;
  z-index: 9;
  display: none;
}

.drop ul li {
  line-height: 20px;
  width: 100%;
}
/*一个很重要的三角形*/
.drop li:first-child:before {
  content: " ";
  font-size: 0;
  line-height: 0;
  margin: 0 auto;
  display: block;
  background-color: #2695f6;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  top: -5px;
  z-index: -1;
}
.drop li {
  background-color: #2695f6;
  z-index: 99;
}
.drop li a {
  color: #fff;
  line-height: 46px;
  font-size: 14px;
  display: block;
  position: relative;
  z-index: 2;
}
.drop li:first-child a {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: -10px;
}
.drop li:last-child a {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: none;
}
.drop li:hover {
  background-color: #0067de;
  z-index: 999;
}
.dropul:hover .drop ul {
	display: block;
}
.operation-box {
  background-color: #ffffff;
  width: 581px;
  height: 578px;
  border-radius: 12px;
  position: absolute;
  right: 178px;
  top: 50%;
  transform: translateY(-50%);
  .outside-box {
    position: relative;
    .top-zlbf {
      height: 72px;
      width: 256px;
      background-color: #00C2FF;
      color: #ffffff;
      font-size: 30px;
      line-height: 72px;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 6px;
      top: -125px;
      z-index: 999;
    }
    .options {
      display: flex;
      justify-content: space-between;
      margin: 87px 111px 70px;
      .login {
        color: #2695F6;
        font-size: 20px;
        border-bottom: 1px solid #2695F6;
      }
      .register {
        color: #999999;
        font-size: 20px;
        border-bottom:0;
		cursor: pointer;
      }
    }
    .form-box {
      font-size: 18px;
      width: 70%;
      margin: 0 auto;
	  .code {
		  display: flex;
		  align-items: center;
	  }
      .el-input__inner {
        border: 0;
        border-bottom: 1px solid #DCDFE6;
        width: 100%;
        outline: 0;
      }
    }
  }
}
.el-icon-key:before{
  position: relative;
  left: -199px;
  top: 28px;
  font-size: 18px;
  color: #606266;
}
.el-icon-phone-outline:before {
  position: relative;
  left: -199px;
  top: 28px;
  font-size: 18px;
  color: #606266;
}
.el-button--primary {
  width: 100%;
  margin-top: 45px;
}
.el-input {
  font-size: 18px;
}
</style>
